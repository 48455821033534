const { _APP_CONFIG } = window;

// api
export const REACT_APP_API = _APP_CONFIG.endpoint;
export const REACT_APP_FRONT_BASE = _APP_CONFIG.baseHostname;
export const REACT_APP_HEALTH_ROUTE = _APP_CONFIG.healthRoute;
export const REACT_APP_MOCKUP = _APP_CONFIG.mockup;
export const REACT_APP_ARRANGER_TOKEN = _APP_CONFIG.arrangerToken;
export const REACT_APP_SHIP_NODE_IDS = _APP_CONFIG.shipNodeIds;
export const REACT_APP_GROUP_ID = _APP_CONFIG.groupID;
export const REACT_APP_WEBSOCKET_CHANNEL = _APP_CONFIG.websocketChannel;

// centrifugo
export const CENTRIFUGO_URL = _APP_CONFIG.centrifugo.url;
export const CENTRIFUGO_TOKEN = _APP_CONFIG.centrifugo.token;
