import { networkAction } from 'helpers/network/networkAction';

import {
  WS_MESSAGE
} from 'constants/app';

import * as Api from 'api';

export const websocketMessageReceived = token => async dispatch => dispatch({
  type: WS_MESSAGE,
  token,
});
