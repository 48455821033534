import * as Colors from '@material-ui/core/colors/';

export default {
  primary: {
    color: Colors.amber,
    light: Colors.amber[50],
    main: Colors.amber[800],
    dark: Colors.amber[900],
    contrastText: '#fff',
  },
  secondary: Colors.grey,
  error: Colors.red,
  contrastThreshold: 6,
  tonalOffset: 0.8,
};
