// material-ui
import { withStyles } from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// components
import App from "components/App";
import Centrifugo from "components/Centrifugo";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  websocketMessageReceived: (...args) =>
    dispatch(Actions.websocketMessageReceived(...args)),
  getDashboard: (...args) => dispatch(Actions.getDashboard(...args)),
  starGitRepo: (...args) => dispatch(Actions.starGitRepo(...args)),
  protectGitRepo: (...args) => dispatch(Actions.protectGitRepo(...args)),
  forceUpdateGitRepo: (...args) =>
    dispatch(Actions.forceUpdateGitRepo(...args)),
  getGitRepos: (filters) => dispatch(Actions.getGitRepos(filters)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    websocketMessageReceived: PropTypes.func,
    getDashboard: PropTypes.func,
    starGitRepo: PropTypes.func,
    protectGitRepo: PropTypes.func,
    forceUpdateGitRepo: PropTypes.func,
    getGitRepos: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      urlParams,
    };
  }

  render() {
    const {
      websocketMessageReceived,
      getDashboard,
      classes,
      starGitRepo,
      protectGitRepo,
      forceUpdateGitRepo,
      getGitRepos,
    } = this.props;

    return (
      <div className={classes.container}>
        <Centrifugo websocketMessageReceived={websocketMessageReceived}>
          <App
            starGitRepo={starGitRepo}
            protectGitRepo={protectGitRepo}
            forceUpdateGitRepo={forceUpdateGitRepo}
            getGitRepos={getGitRepos}
            getDashboard={getDashboard}
          />
        </Centrifugo>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
