import React from 'react';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// styles
import styles from './styles';

// config
import { REACT_APP_LOGO } from 'config';

class Loading extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string,
  };

  render() {
    const { classes, message } = this.props;

    return (
      <Div100vh>
        <Grid
          container
          className={classes.root}
          alignContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              marginTop: 40,
            }}
          >
            <CircularProgress style={{ color: '#37474f' }} />
            <Typography variant="caption" style={{ color: 'white' }}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Div100vh>
    );
  }
}

export default withStyles(styles)(Loading);
