// import PropTypes from 'prop-types';
// material-ui
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GitReposList from "components/GitReposList";
import { REACT_APP_ARRANGER_TOKEN } from "config";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function GitRepos({
  groupID,
  starGitRepo,
  protectGitRepo,
  forceUpdateGitRepo,
  getGitRepos,
}) {
  const [gitRepos, setGitRepos] = React.useState([]);
  const classes = useStyles(styles);

  React.useEffect(() => {
    refresh();
  }, []);

  async function refresh() {
    const filters = [
      {
        name: "git_repos.groupID",
        comparison: "eq",
        value: groupID,
      },
    ];
    apiCall("GET", `gitRepos?filters=${JSON.stringify(filters)}`).then(
      (json) => {
        if (json) setGitRepos(json);
      }
    );
  }

  async function apiCall(method, endpoint, body) {
    const resp = await fetch(
      `https://api.arranger.wid.la/arranger/${endpoint}`,
      {
        credentials: "include",
        headers: {
          "content-type": "application/json",
          "auth-server-token": REACT_APP_ARRANGER_TOKEN,
        },
        method: method,
        body,
      }
    );

    let json;
    try {
      json = await resp.json();
      return json;
    } catch (e) {
      return undefined;
    }
  }

  return (
    <Paper elevation={2} style={{ width: "100%" }}>
      <GitReposList
        gitRepos={gitRepos}
        starGitRepo={(id) => apiCall("GET", `gitRepos/${id}/star`)}
        forceUpdateGitRepo={(id) =>
          apiCall("GET", `gitRepos/${id}/forceUpdate`)
        }
        protectGitRepo={(id) => apiCall("GET", `gitRepos/${id}/protect`)}
        refresh={refresh}
      />
    </Paper>
  );
}
