import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Loading from "components/Loading";
// custom
import ServerWidget from "components/ServerWidget";
import { REACT_APP_GROUP_ID, REACT_APP_SHIP_NODE_IDS } from "config";
import GitRepos from "hooks/GitRepos";
import ShipNode from "hooks/ShipNode";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getDashboard: PropTypes.func,
    starGitRepo: PropTypes.func,
    protectGitRepo: PropTypes.func,
    forceUpdateGitRepo: PropTypes.func,
    getGitRepos: PropTypes.func,
  };

  state = {
    dahboard: undefined,
    loading: false,
  };

  constructor(...args) {
    super(...args);
    const { getDashboard } = this.props;
    getDashboard().then((r) => {
      if (r.success) {
        this.setState({
          dashboard: r.payload,
          loading: false,
        });
      }
    });
  }

  render() {
    const {
      classes,
      starGitRepo,
      protectGitRepo,
      forceUpdateGitRepo,
      getGitRepos,
    } = this.props;
    const { dashboard, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className={classes.dashboard}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="title"
              style={{
                fontSize: 42,
              }}
            >
              Virtual Machines
            </Typography>
          </Grid>
          {dashboard &&
            dashboard.map((d) => (
              <Grid item style={{ flex: 1, minWidth: 100 }}>
                <ServerWidget
                  theme="light"
                  cpu={d.cpu}
                  ram={d.ram}
                  disk={d.disk}
                  title={d.title}
                  id={d.id}
                  callbackURL={d.callbackURL}
                />
              </Grid>
            ))}
          {REACT_APP_SHIP_NODE_IDS && REACT_APP_SHIP_NODE_IDS.length ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="title"
                  style={{
                    fontSize: 42,
                  }}
                >
                  Ship Nodes
                </Typography>
              </Grid>
              {REACT_APP_SHIP_NODE_IDS.map((i) => (
                <Grid item xs={6}>
                  <ShipNode shipNodeID={i} xs={6} />
                </Grid>
              ))}
            </>
          ) : (
            []
          )}
          {REACT_APP_GROUP_ID ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="title"
                  style={{
                    fontSize: 42,
                  }}
                >
                  Git Repos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <GitRepos
                  groupID={REACT_APP_GROUP_ID}
                  starGitRepo={starGitRepo}
                  protectGitRepo={protectGitRepo}
                  forceUpdateGitRepo={forceUpdateGitRepo}
                  getGitRepos={getGitRepos}
                />
              </Grid>
            </>
          ) : (
            []
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(App);
