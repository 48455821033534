import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "arranger";
const shipNodes = `${service}/shipNodes`;

export function getShipNodes(filters) {
  const url = `${shipNodes}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getShipNodeById(id) {
  const url = `${shipNodes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateShipNode(id, body) {
  const url = `${shipNodes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteShipNode(id) {
  const url = `${shipNodes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createShipNode(body) {
  const url = `${shipNodes}`;
  return {
    method: POST,
    url,
    body,
  };
}
