import {
    GET,
  } from 'constants/methods';

  import { REACT_APP_HEALTH_ROUTE } from 'config';

  export function getDashboard() {
    const url = REACT_APP_HEALTH_ROUTE;
    return {
      method: GET,
      url,
    };
  }
