export default theme => ({
  card: {
    // background: 'linear-gradient(145deg, rgba(238,243,248,1) 0%, rgba(205,222,236,1) 100%)',
    borderRadius: 10,
    height: 40,
    marginLeft: 20,
    position: 'relative',
    width: 'calc(100% - 20px)',
    boxShadow: 'inset -2px -2px 8px rgb(255,255,255, 0.8), inset 2px 2px 8px rgb(96,125,139, 0.4)'
  },
  value: {
    // background: 'linear-gradient(145deg, rgba(238,243,248,1) 0%, rgba(205,222,236,1) 100%)',
    borderRadius: 10,
    height: 40,
    background: '#DDE8F2',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
