import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "arranger";
const shipStages = `${service}/shipStages`;

export function getShipStages(filters) {
  const url = `${shipStages}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getShipStageById(id) {
  const url = `${shipStages}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateShipStage(id, body) {
  const url = `${shipStages}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteShipStage(id) {
  const url = `${shipStages}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createShipStage(body) {
  const url = `${shipStages}`;
  return {
    method: POST,
    url,
    body,
  };
}
