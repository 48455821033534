import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// custom
import Card from "components/Card";
import LineChart from "components/LineChart";
import { REACT_APP_WEBSOCKET_CHANNEL } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Cpu from "./cpu.svg";
import Disk from "./disk.svg";
import Ram from "./ram.svg";
// styles
import styles from "./styles";

class ServerWidget extends Component {
  static propTypes = {
    classes: PropTypes.object,
    backgroundColor: PropTypes.string,
    cpu: PropTypes.string,
    disk: PropTypes.string,
    ram: PropTypes.string,
    title: PropTypes.string,
    theme: PropTypes.string,
    callbackURL: PropTypes.string,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { cpu, ram, disk, id } = this.props;
    const { WebsocketCenter } = this.context;
    this.state = {
      cpu,
      ram,
      disk,
    };
    WebsocketCenter.subscribe(REACT_APP_WEBSOCKET_CHANNEL, (m) => {
      const data = m.data;
      if (data.id === id) {
        if (data.cpu) {
          this.setState({
            cpu: data.cpu,
          });
        }
        if (data.disk) {
          this.setState({
            disk: data.disk,
          });
        }
        if (data.ram) {
          this.setState({
            ram: data.ram,
          });
        }
      }
    });
  }

  render() {
    const { classes, backgroundColor, callbackURL, theme, title } = this.props;

    const { disk, ram, cpu } = this.state;

    return (
      <div
        onClick={() => window.open(callbackURL, "_blank")}
        // style={{ cursor: callbackURL ? 'pointer' : undefined }}
      >
        <Card backgroundColor={backgroundColor} theme={theme}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography
                variant="h6"
                style={{
                  color: theme === "dark" ? "white" : undefined,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "block",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item style={{ width: 30 }}>
                  <img src={Cpu} width="30" />
                </Grid>
                <Grid item style={{ width: "calc(100% - 30px)" }}>
                  <LineChart value={cpu} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item style={{ width: 30 }}>
                  <img src={Disk} width="30" />
                </Grid>
                <Grid item style={{ width: "calc(100% - 30px)" }}>
                  <LineChart value={disk} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item style={{ width: 30 }}>
                  <img src={Ram} width="30" />
                </Grid>
                <Grid item style={{ width: "calc(100% - 30px)" }}>
                  <LineChart value={ram} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(ServerWidget);
