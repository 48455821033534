import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// styles
import styles from './styles';

class Card extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.object,
    backgroundColor: PropTypes.string,
  };

  render() {
    const { 
      classes, 
      children,
      backgroundColor,
      theme,
    } = this.props;

    return (
      <div 
        className={backgroundColor ? classes.coloredCard : classes.card}
        style={{
          background: backgroundColor,
        }}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Card);
