import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// styles
import styles from './styles';

class LineChart extends Component {
  static propTypes = {
    classes: PropTypes.object,
    value: PropTypes.number,
  };

  render() {
    const { classes, value } = this.props;

    let background = "#DDE8F2";
    let color = "#8F9FB2";
    let boxShadow = 'inset -2px -2px 4px rgb(255,255,255, 0.8), inset 2px 2px 4px rgb(96,125,139, 0.4)';

    if (value >= 75) { background = "#ff9800"; color="white"; boxShadow = 'inset -2px -2px 4px #ffa726, inset 2px 2px 4px #f57c00' }
    if (value >= 90) { background = "#f44336"; color="white"; boxShadow = 'inset -2px -2px 4px #ef5350, inset 2px 2px 4px #b71c1c' }

    return (
      <div 
        className={classes.card}
      >
        <div
          className={classes.value}
          style={{ width: `${value}%`, background, color, boxShadow, transition: 'width 1s, background 1s, color 1s' }}
        >
          <Typography variant="body1" color="inherit">
            {value}%
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LineChart);
