// material-ui
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// custom components
import Root from "components/Root";
import React from "react";
import theme from "themes/theme";
import "./App.css";

function App() {
  const muiTheme = createMuiTheme({
    palette: { ...theme },
    typography: {
      fontFamily: ["open-sans, sans-serif"].join(","),
    },
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Root />
    </MuiThemeProvider>
  );
}

export default App;
