// material-ui
import {
  Avatar,
  Button,
  Collapse,
  Divider,
  Grid,
  Icon,
  Paper,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Alert, Skeleton } from "@material-ui/lab";
import { REACT_APP_ARRANGER_TOKEN } from "config";
import moment from "moment";
import React from "react";

export default function ShipNode({ shipNodeID }) {
  const [fetchedShipNode, setFetchedShipNode] = React.useState(null);
  const [selectedStage, setSelectedStage] = React.useState(0);
  const [expanded, setExpanded] = React.useState(true);

  React.useEffect(() => {
    fetch(`https://api.arranger.wid.la/arranger/shipNodes/${shipNodeID}`, {
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "auth-server-token": REACT_APP_ARRANGER_TOKEN,
      },
      method: "GET",
    }).then(async (resp) => {
      let json;
      try {
        json = await resp.json();
      } catch (e) {
        console.log(e);
      }
      const fsn = json;
      for (const i in fsn.stages) {
        if (Object.hasOwnProperty.call(fsn.stages, i)) {
          const node = fsn.stages[i];
          for (const j in node.services) {
            if (Object.hasOwnProperty.call(node.services, j)) {
              const service = node.services[j];
              if (
                service.latestVersion !== service.currentVersion &&
                service.latestVersion !== "null"
              ) {
                node.needUpdate = true;
              }
            }
          }
        }
      }
      setFetchedShipNode(fsn);
    });
  }, []);

  if (!fetchedShipNode) return <div />;

  return (
    <Paper elevation={2}>
      <Grid
        container
        spacing={1}
        justify="space-between"
        style={{ padding: 12 }}
      >
        <Grid
          item
          style={{
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            src={`https://git.wid.la/uploads/-/system/project/avatar/${fetchedShipNode.gitlabID}/icon.png`}
          />
          <div style={{ paddingLeft: 12 }}>
            <Typography variant="h6">{fetchedShipNode.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {fetchedShipNode.groupName}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          {fetchedShipNode ? (
            <div>
              <Typography
                variant="h5"
                color="textSecondary"
                display="block"
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-end",
                }}
              >
                <SvgIcon viewBox="0 -4 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M9.828 3H13v3.172L6.172 13 3 9.828 9.828 3zM8.414 1.586A2 2 0 0 1 9.828 1H15v5.172a2 2 0 0 1-.586 1.414l-6.828 6.828a2 2 0 0 1-2.829 0l-3.171-3.172a2 2 0 0 1 0-2.828l6.828-6.828zM11 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                  ></path>
                </SvgIcon>
                {fetchedShipNode.stages[selectedStage].version}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <SvgIcon style={{ marginTop: 8 }}>
                  <path
                    fill-rule="evenodd"
                    d="M11.876 8.992a4.002 4.002 0 0 1-7.752 0A1.01 1.01 0 0 1 4 9H1a1 1 0 0 1 0-2h3c.042 0 .083.003.124.008a4.002 4.002 0 0 1 7.752 0A1.01 1.01 0 0 1 12 7h3a1 1 0 1 1 0 2h-3c-.042 0-.083-.003-.124-.008zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                  ></path>
                </SvgIcon>
                <a
                  style={{ color: "#2196f3", textDecoration: "none" }}
                  target="_blank"
                  href={`https://git.wid.la/${fetchedShipNode.groupName}/deploy/${fetchedShipNode.name}/-/tree/${fetchedShipNode.stages[selectedStage].commit}`}
                >
                  <b>
                    {fetchedShipNode.stages[selectedStage].commit.substring(
                      0,
                      8
                    )}
                  </b>
                </a>
                <span style={{ paddingLeft: 8 }}>
                  {moment(
                    fetchedShipNode.stages[selectedStage].buildDate
                  ).fromNow()}
                </span>
              </Typography>
            </div>
          ) : (
            []
          )}
        </Grid>
      </Grid>
      <Divider />
      {fetchedShipNode === null ? (
        <Skeleton style={{ flex: 1, position: "relative", height: 60 }} />
      ) : (
        <div style={{ flex: 1, position: "relative" }}>
          <AntTabs
            value={selectedStage}
            onChange={(e, v) => {
              setSelectedStage(v);
            }}
            aria-label="simple tabs example"
          >
            {fetchedShipNode.stages.map((n) => (
              <AntTab
                color={n.needUpdate ? "#ff9800" : "#4caf50"}
                label={
                  <span>
                    <Icon
                      className="fas fa-code-branch"
                      style={{ fontSize: 12 }}
                    />{" "}
                    {n.name}{" "}
                    {n.needUpdate ? (
                      <span
                        className="fas fa-exclamation-triangle"
                        style={{ fontSize: 12, color: "#ff9800" }}
                      />
                    ) : (
                      <span
                        className="fas fa-check-circle"
                        style={{ fontSize: 12, color: "#4caf50" }}
                      />
                    )}
                  </span>
                }
              />
            ))}
          </AntTabs>
        </div>
      )}
      {expanded ? (
        <Collapse expanded={expanded} in collapsedHeight={40}>
          <Grid container spacing={1} style={{ padding: 12 }}>
            <Grid item xs={12}>
              {fetchedShipNode.stages[selectedStage].needUpdate ? (
                <Alert
                  severity="warning"
                  action={
                    <Button
                      size="small"
                      variant="outlined"
                      style={{
                        color: "#ff9800",
                        borderColor: "#ff9800",
                        textTransform: "none",
                      }}
                      onClick={() =>
                        window.open(
                          `https://git.wid.la/-/ide/project/${fetchedShipNode.groupName}/deploy/${fetchedShipNode.name}/tree/alpha/-/.gitlab-ci.yml/`,
                          "_blank"
                        )
                      }
                    >
                      Update Now
                    </Button>
                  }
                >
                  <Typography variant="body2">
                    This deploy node needs an update.
                  </Typography>
                </Alert>
              ) : (
                <Alert
                  severity="success"
                  action={
                    <Button
                      size="small"
                      variant="outlined"
                      style={{
                        color: "#4caf50",
                        borderColor: "#4caf50",
                        textTransform: "none",
                      }}
                      onClick={() =>
                        window.open(
                          `https://git.wid.la/-/ide/project/${fetchedShipNode.groupName}/deploy/${fetchedShipNode.name}/tree/alpha/-/.gitlab-ci.yml/`,
                          "_blank"
                        )
                      }
                    >
                      Web IDE
                    </Button>
                  }
                >
                  <Typography variant="body2">
                    Good Job! This deploy node is up to date.
                  </Typography>
                </Alert>
              )}
            </Grid>
            {fetchedShipNode.stages[selectedStage].services.map((s) => (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={`https://git.wid.la/uploads/-/system/project/avatar/${s.gitlabID}/icon.png`}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 8,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    {s.name[0]}
                  </Avatar>
                  {s.gitlabID ? (
                    <a
                      href={`https://git.wid.la/projects/${s.gitlabID}`}
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      <Typography variant="body2">
                        <b>{s.name}</b>
                      </Typography>
                    </a>
                  ) : (
                    <Typography variant="body2">{s.name}</Typography>
                  )}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 8,
                      color:
                        s.latestVersion !== s.currentVersion &&
                        s.latestVersion !== "null"
                          ? "#ff9800"
                          : s.latestVersion === s.currentVersion
                          ? "#4caf50"
                          : undefined,
                    }}
                  >
                    <div>
                      <SvgIcon style={{ fontSize: 14 }} viewBox="0 -4 20 20">
                        <path
                          fill-rule="evenodd"
                          d="M9.828 3H13v3.172L6.172 13 3 9.828 9.828 3zM8.414 1.586A2 2 0 0 1 9.828 1H15v5.172a2 2 0 0 1-.586 1.414l-6.828 6.828a2 2 0 0 1-2.829 0l-3.171-3.172a2 2 0 0 1 0-2.828l6.828-6.828zM11 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                        ></path>
                      </SvgIcon>
                    </div>
                    <div>{s.currentVersion}</div>
                  </Typography>
                </div>
                <div>
                  {s.latestVersion === "null" ? (
                    <Typography variant="caption" color="textSecondary">
                      No tag found
                    </Typography>
                  ) : (
                    []
                  )}
                  {s.latestVersion === s.currentVersion ? (
                    <CheckCircleOutlineIcon
                      style={{ color: "#4caf50", fontSize: 16 }}
                    />
                  ) : (
                    []
                  )}
                  {s.latestVersion !== s.currentVersion &&
                  s.latestVersion !== "null" ? (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 8,
                        color: "#ff9800",
                      }}
                    >
                      <SvgIcon style={{ fontSize: 14 }} viewBox="0 0 18 18">
                        <path
                          fill-rule="evenodd"
                          d="M9.828 3H13v3.172L6.172 13 3 9.828 9.828 3zM8.414 1.586A2 2 0 0 1 9.828 1H15v5.172a2 2 0 0 1-.586 1.414l-6.828 6.828a2 2 0 0 1-2.829 0l-3.171-3.172a2 2 0 0 1 0-2.828l6.828-6.828zM11 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                        ></path>
                      </SvgIcon>
                      {`${s.latestVersion} Available`}
                    </Typography>
                  ) : (
                    []
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      ) : (
        []
      )}
    </Paper>
  );
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "black",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#ff9800",
      opacity: 1,
    },
    "&$selected": {
      color: "#ff9800",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#ff9800",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} style={{ color: props.color }} />);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));
