// material-ui
import {
  Avatar,
  Icon,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class GitReposList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    gitRepos: PropTypes.array,
    onSelect: PropTypes.func,
    starGitRepo: PropTypes.func,
    protectGitRepo: PropTypes.func,
    forceUpdateGitRepo: PropTypes.func,
    refresh: PropTypes.func,
  };

  onSelect(selectedGitRepo) {
    const { onSelect } = this.props;
    this.setState({ selectedGitRepo });
    if (onSelect) {
      onSelect(selectedGitRepo);
    }
  }

  protect = (d) => (e) => {
    const { protectGitRepo, refresh } = this.props;
    e.stopPropagation();
    e.preventDefault();
    protectGitRepo(d.id).then(refresh);
  };

  star = (d) => (e) => {
    const { starGitRepo, refresh } = this.props;
    e.stopPropagation();
    e.preventDefault();
    starGitRepo(d.id).then(refresh);
  };

  forceUpdate = (d) => (e) => {
    const { forceUpdateGitRepo, refresh } = this.props;
    e.stopPropagation();
    e.preventDefault();
    forceUpdateGitRepo(d.id).then(refresh);
  };

  render() {
    const { gitRepos } = this.props;

    return (
      <Table
        data={gitRepos}
        noHeader
        meta={[
          {
            path: "gitlabID",
            title: "",
            numeric: false,
            width: 50,
            render: (t, d) => (
              <Avatar
                src={`https://git.wid.la/uploads/-/system/project/avatar/${t}/icon.png`}
                style={{
                  textTransform: "uppercase",
                }}
              >
                {d.name[0]}
              </Avatar>
            ),
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
            transform: (t, d) => `${t} ${d.image}`,
            render: (t, d) => (
              <>
                <Typography>{d.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {d.image}
                </Typography>
              </>
            ),
          },
          {
            path: "latestVersion",
            title: "Version",
            numeric: false,
            render: (t) => (
              <Typography variant="body2">
                <SvgIcon viewBox="0 -4 20 20" style={{ fontSize: 18 }}>
                  <path
                    fill-rule="evenodd"
                    d="M9.828 3H13v3.172L6.172 13 3 9.828 9.828 3zM8.414 1.586A2 2 0 0 1 9.828 1H15v5.172a2 2 0 0 1-.586 1.414l-6.828 6.828a2 2 0 0 1-2.829 0l-3.171-3.172a2 2 0 0 1 0-2.828l6.828-6.828zM11 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                  ></path>
                </SvgIcon>
                {t}
              </Typography>
            ),
          },
          {
            path: "gcVersion",
            title: "GC Version",
          },
          {
            path: "language",
            title: "Language",
          },
          {
            path: "runner",
            title: "Runner",
          },
          {
            path: "protected",
            title: "Protected",
            numeric: false,
            render: (t, d) => (
              <>
                {d.protected ? (
                  <Typography
                    variant="caption"
                    style={{
                      background: "#108548",
                      color: "white",
                      borderRadius: "0.25rem",
                      cursor: "pointer",
                      padding: "2px 5px",
                      fontSize: "12px",
                    }}
                  >
                    protected
                  </Typography>
                ) : (
                  <Tooltip title="Click to protect">
                    <Typography
                      onClick={this.protect(d)}
                      variant="caption"
                      style={{
                        background: "#9e9e9e",
                        color: "white",
                        borderRadius: "0.25rem",
                        padding: "2px 5px",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      unprotected
                    </Typography>
                  </Tooltip>
                )}
              </>
            ),
          },
          {
            path: "star",
            title: "",
            numeric: false,
            width: 20,
            render: (t, d) =>
              d.star ? (
                <i style={{ color: "#CDAA35" }} class="fas fa-star"></i>
              ) : (
                <Tooltip title="Click to Star">
                  <Icon
                    onClick={this.star(d)}
                    style={{ color: "#bdbdbd", cursor: "pointer" }}
                    className="far fa-star"
                    fontSize="inherit"
                  ></Icon>
                </Tooltip>
              ),
          },
          {
            path: "visibility",
            title: "",
            numeric: false,
            width: 20,
            render: (t) => {
              switch (t) {
                case "private":
                  return <i class="fad fa-user-secret"></i>;
                case "public":
                  return <i class="fad fa-user-secret"></i>;
                case "internal":
                  return <i class="fas fa-shield-alt"></i>;
                default:
                  return <i class="fas fa-question"></i>;
              }
            },
          },
          {
            path: "notes",
            title: "",
            numeric: false,
            width: 20,
            render: (t) =>
              t ? (
                <Tooltip title={t}>
                  <Icon class="fas fa-clipboard" />
                </Tooltip>
              ) : (
                []
              ),
          },
          {
            path: "id",
            title: "",
            numeric: false,
            width: 20,
            render: (t, d) => (
              <Tooltip title="Force Update">
                <IconButton size="small" onClick={this.forceUpdate(d)}>
                  <Icon fontSize="inherit" className="fas fa-sync-alt" />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            path: "id",
            title: "",
            numeric: false,
            width: 20,
            render: () => <NavigateNextIcon />,
          },
        ]}
        title={"Git Repos"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(GitReposList);
