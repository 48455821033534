import { networkAction } from 'helpers/network/networkAction';

import {
  GET_DASHBOARD
} from 'constants/app';

import * as Api from 'api';

export const getDashboard = () => async dispatch => networkAction(
  dispatch,
  GET_DASHBOARD,
  Api.getDashboard,
  [],
);
